import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import Stores from '../components/StoreContact/Stores'
import SEO from '../components/SEO'
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"

const Contact = ({ data }) => {
  const postNode = {
    title: `Store Locations & Contact - ${config.siteTitle}`,
  }

const Wrapper = styled.div`
  margin: 0 auto 5rem auto !important;
  padding: 5rem 0 5rem 0;
  max-width: 46vw;
  @media (max-width: 768px) {
    max-width: 90vw;
  }
`
const storeData = useStaticQuery(graphql`
  query ContactPageStoreQuery {
    allContentfulStore(sort: {fields: [reedsBrandedStore], order: [DESC]}){
      edges {
        node {
          storeName
          localName
          addressLine1
          addressLine3
          phoneNumber
          storeHours {
            internal {
              content
            }
          }
          facebookUrl
          instagramHandle
          twitterHandle
        }
      }
    }
  }
  `)
  return (
    <Layout>
      <Helmet>
        <title>{`Store Locations & Contact - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <Wrapper>
        <PageTitle>Store Locations & Contact</PageTitle>
        <Stores storeData={storeData}/>
      </Wrapper>
    </Layout>
  )
}

export default Contact
