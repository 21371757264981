//
// We're using https://github.com/simple-icons/simple-icons for the icons and they are delivered by their CDN.
import React from 'react'
import styled from 'styled-components'
import SocialIcon from './SocialIcon';

const Wrapper = styled.div``

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
`
// ["twitterHandle", "instagramHandle", "facebookUrl"]
const SocialIcons = (props) => {
  const icons = Object.keys(props.data).map((key, index)=> {
    if (props.data[key]) {
      switch (key) {
        case "twitterHandle":
          return <SocialIcon key={index} icon="twitter" url={`www.twitter.com/${props.data[key]}/`}/>
        case "instagramHandle":
          return <SocialIcon key={index} icon="instagram" url={`www.instagram.com/${props.data[key]}/`}/>
        case "facebookUrl":
          return <SocialIcon key={index} icon="facebook" url={props.data[key]}/>
        default:
          break;
      }
    }
  })
  return (
    <Wrapper className={props.className}>
        <List>
          {icons}
        </List>
    </Wrapper>
  )}
  
  export default SocialIcons
