import React, { Component } from 'react'
import Store from './Store'
import styled from 'styled-components'

const StoresWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin: 0 auto;
`
export default class Stores extends Component {
  render() {
    const stores = this.props.storeData.allContentfulStore.edges.map((store, index) => (
      <Store key={index} data={store} />
      )
    )
    return <StoresWrapper>{stores}</StoresWrapper>
  }
}
