//
// We're using https://github.com/simple-icons/simple-icons for the icons and they are delivered by their CDN.
import React from 'react'
import styled from 'styled-components'
import tw from '../../images/tw.svg'
import fb from '../../images/fb.svg'
import ig from '../../images/ig.svg'

const Wrapper = styled.div`
  margin-right: 23px;
`

const SocialIcon = (props) => {
    switch (props.icon) {
      case "facebook":
        return (
          <Wrapper>
            <a href={`https://${props.url}`}><img height="46" width="46" src={fb} /></a>
          </Wrapper>
        )
      case "instagram":
        return (
          <Wrapper>
            <a href={`https://${props.url}`}><img height="46" width="46" src={ig} /></a>
          </Wrapper>
        )
      case "twitter":
        return (
          <Wrapper>
            <a href={`https://${props.url}`}><img height="46" width="46" src={tw} /></a>
          </Wrapper>
        )
      default:
        break;
    }
  }
  
  export default SocialIcon
