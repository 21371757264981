import React, { Component } from 'react'
import SocialIcons from '../utils/SocialIcons'
import styled from 'styled-components'

const StoreContactItem = styled.p`
  font-size: 1.25rem;
  color: ${props => props.theme.colors.quadiary};
  margin-bottom: 3.25rem;
  @media (max-width: 768px) {
    font-size: 1.125rem;
    margin-bottom: 1.38rem;
  }
`

const Hour = styled(StoreContactItem)`
  &.indent-hours {
    margin-left: 3.3em;
  }
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-bottom: 1.125rem;
`
const StoreTitle = styled.h3`
  @media (max-width: 768px) {
    font-size: 1.5625rem;
  }
  font-size: 1.75rem;
  letter-spacing: 1.5px;
  color: ${props => props.theme.colors.base};
  &:after {
    content: ""; /* This is necessary for the pseudo element to work. */ 
    display: block; /* This will put the pseudo element on its own line. */
    width: 28vw; /* Change this to whatever width you want. */
    padding-top: 19px; /* This creates some space between the element and the border. */
    border-bottom: 1px solid ${props => props.theme.colors.base}; /* This creates the border. */
    margin-bottom: 1.625rem; /* This is the bottom margin for the div */
  }
`
const StyledSocialIcons = styled(SocialIcons)`
  margin-top: 2.3125rem;
  @media (max-width: 768px) {
    ul {
      padding: 0;
    }
  }
`
const Wrapper = styled.div`
  margin-bottom: 7.8125rem;
  @media (max-width: 768px) {
    margin-bottom: 3.2em;
  }
`
export default class Store extends Component {

  render() {
    const store = this.props.data.node
    let hours
    if (store.storeHours.internal.content.includes("\n")) {
      const text = store.storeHours.internal.content.slice(7).split('\n')
      const first = <Hour>{store.storeHours.internal.content.split('\n')[0]}</Hour>
      const times = text.slice(1).map((txt,index) => <Hour key={index}>{txt}</Hour>)
      hours = <>
        {first}
        {times}
      </>
    } else {
      hours = <Hour>{store.storeHours.internal.content}</Hour>
    }
    const socialKeys = ["twitterHandle", "instagramHandle", "facebookUrl"]
    const socialObj = Object.keys(store)
              .filter( key => socialKeys.includes(key) )
              // eslint-disable-next-line no-return-assign
              .reduce( (res, key) => (res[key] = store[key], res), {} );
    return (
      <Wrapper>
        <StoreTitle>{store.storeName}</StoreTitle>
        <StoreContactItem>{`Address: ${store.addressLine1}`}</StoreContactItem>
        <StoreContactItem>{`${store.addressLine3}`}</StoreContactItem>
        <StoreContactItem>{`Phone: (${store.phoneNumber.toString().slice(0,3)}) ${store.phoneNumber.toString().slice(3,6)}-${store.phoneNumber.toString().slice(6)}`}</StoreContactItem>
        <StoreContactItem>Email: <a href="mailto:help@reeds.market">help@reeds.market</a></StoreContactItem>
        {hours}
        <StyledSocialIcons data={socialObj} />
      </Wrapper>
    )
  }
}
