import React from 'react'
import styled from 'styled-components'

const Title = styled.h1`
  font-size: 2.1875rem;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 0 4.375rem 0;
  line-height: 1.2;
  color: ${props => props.theme.colors.base};
  @media (max-width: 768px) {
    font-size: 1.6875rem;
  }
`

const PageTitle = props => {
  return <Title>{props.children}</Title>
}

export default PageTitle
